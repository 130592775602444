@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');

/*
CONTENT:
	1. GLOBAL
	2. HEADER
	3. ABOUT
	4. PORTFOLIO
	5. SINGLE PROJECT PAGE
	6. STAGES
	7. OFFER
	8. CONTACT
*/

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

:root {
	--main-bg-color: #232429;
	--secondary-bg-color: #2c2c2e;
	--main-text-color: #e4e4e4;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	background-color: var(--main-bg-color);
	color: var(--main-text-color);

	max-width: 2000px;
	margin-left: auto;
	margin-right: auto;
}

// 1. GLOBAL
a {
	text-decoration: none;
	color: var(--main-text-color);
}

h2 {
	font-size: 2.2rem;
	text-align: center;
}

h3 {
	font-size: 1.2rem;
}

hr {
	margin-top: 15px;
	width: 200px;
	border: 0;
	border-top: 3px #9c9c9c solid;
	border-radius: 4px;
}

.icon {
	width: 30px;
	margin-right: 14px;

	@media only screen and (max-width: 360px) {
		display: none;
	}
}

.flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-centered {
	justify-content: center;
	align-items: center;
	position: relative;
}

.relative {
	position: relative;
}

// 2. HEADER
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 30px;
	background-color: #1a1a1a;
	height: max(100px, 15vh);

	&.header-secondary {
		height: max(100px, 15vh);

		@media only screen and (max-width: 500px) {
			height: max(200px, 15vh);
		}
	}

	@media only screen and (max-width: 500px) {
		flex-direction: column;
		justify-content: space-around;
		height: max(300px, 45vh);
	}

	@media only screen and (min-width: 501px) and (max-width: 850px) {
		height: max(250px, 25vh);
	}

	@media screen and (min-width: 850px) {

		&:not(.header-secondary) {
			position: sticky;
			top: 0;
			z-index: 2;
		}

		&.header-secondary {
			padding-right: 10vw;
		}
	}

	.logo-container {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 1.4rem;

		.logo-link {
			height: 100%;
			display: flex;
			align-items: center;
			color: var(--main-text-color);
		}

		.header--logo {
			height: 100px;
		}
	}

	ul {
		display: flex;
		margin: 14px 0 14px 14px;
		gap: min(1.10vw, 1.5rem);
		white-space: nowrap;

		@media only screen and (max-width: 850px) {
			flex-direction: column;
			gap: 0;
		}
	}

	li {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			position: relative;
			background: none;
			border: 1px solid transparent;
			width: 100%;
			padding: 8px;
			letter-spacing: 0.3rem;
			font-weight: 400;
			font-size: clamp(0.9rem, 1.5vw, 1.3rem);
			cursor: pointer;
			color: var(--main-text-color);

			@media only screen and (max-width: 850px) {
				padding: 8px;
				border-bottom: #ccc solid 1px;
			}

			@media only screen and (min-width: 851px) {
				&::after {
					position: absolute;
					margin-left: auto;
					margin-right: auto;
					top: 35px;
					left: 0;
					right: 0;
					content: "";
					width: 0px;
					transition: all 300ms;
					border-bottom: solid rgb(255, 0, 0) 2px;
				}

				&:hover::after {
					width: 80px;
				}
			}

			@media only screen and (max-width: 850px) {
				&:hover {
					border: solid #ccc 1px;
				}
			}
		}
	}
}

// 3. ABOUT
.about-section {
	background: var(--main-bg-color);
	padding: 100px 10vw;
	color: var(--main-text-color);

	@media only screen and (max-width: 500px) {
		padding: 100px 10px;
	}
}

.about {
	margin-top: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 50px;

	.about--photo {
		max-width: 400px;
		width: 100%;
	}

	@media only screen and (max-width: 1000px) {
		flex-direction: column-reverse;
		gap: 0;
	}
}

.about--text {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	max-width: 500px;
	gap: 40px;
	padding: 30px;
	letter-spacing: 0.10rem;
	font-size: 1.1rem;
	font-family: 'Prompt', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

	@media only screen and (min-width: 1200px) {
		text-align: justify;
	}

	@media only screen and (max-width: 500px) {
		padding: 40px 10px 80px 10px;
		text-align: left;
	}
}

// 4. PORTFOLIO
.projects-section {
	padding: 100px 2vw;
	background-color: var(--secondary-bg-color);
	text-align: center;

	h2 {
		font-size: clamp(26px, 3vw, 38px);
	}

	h3 {
		font-size: clamp(20px, 2.8vw, 32px);
	}

	p {
		padding-top: 10px;
	}
}

.projects {
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
	padding: 30px 2vw;
}

.project-container {
	position: relative;
	width: 43vw;
	height: 100%;
	display: flex;
	gap: 30px;
	justify-content: space-evenly;
	align-items: center;
	padding-top: 6px;
	margin-bottom: 30px;

	@media only screen and (max-width: 1300px) {
		width: 100%;
	}

	.project--enter {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		pointer-events: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 12px;
		width: 100%;
		height: 100%;
		padding-top: 6px;

		@media only screen and (max-width: 1300px) {
			width: 100%;
		}

		font-size: 34px;
		transition: opacity 200ms;
	}

	.project--img:hover~.project--enter {
		opacity: 1;
	}

	.project--img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: opacity 200ms;
		cursor: pointer;
	}
}

// 5. SINGLE PROJECT PAGE
.project-page {
	padding: 60px 20px;

	h3 {
		padding-top: 10px;
	}

	.project-page--photos {
		margin-top: 40px;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
	}

	.project-page--img {
		width: min(40vw, 1000px);
		height: min(50vh, 33vw);
		object-fit: cover;

		@media only screen and (max-width: 850px) {
			width: 90vw;
			height: min(40vh, 40vw);
		}
	}
}

// 6. STAGES
.stages-section {
	padding: 60px 10vw;
	color: var(--main-text-color);
	background-color: var(--main-bg-color);

	.stages--container {
		margin-top: 60px;
		width: 100%;
		gap: 80px;
		display: grid;
		place-items: center;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		@media only screen and (min-width: 601px) and (max-width: 1450px) {
			grid-template-columns: 1fr 1fr;
			gap: 16px;
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: 1fr;
			gap: 0;
		}

	}

	.stages--single {
		height: 500px;
		max-width: 400px;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: 14px;

		@media only screen and (min-width: 601px) and (max-width: 1450px) {
			height: 600px;
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}
	
	.stages--circle {
		
		position: relative;
		padding-top: 20px;
		min-height: 170px;
		width: 170px;
		border-radius: 50%;
		background-color: rgb(90, 90, 90);
		display: flex;
		text-align: center;
		display: flex;
		align-items: center;
		gap: 16px;

		@media only screen and (min-width: 601px) and (max-width: 1450px) {
			height: 300px;
			width: 300px;
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: 1fr;
			width: 250px;
			height: 250px;
		}

		p {
			text-align: center;
			margin-top: 20px;
			font-size: 18px;
			letter-spacing: 1.3px;

			@media only screen and (min-width: 601px) and (max-width: 1450px) {
				font-size: 26px;
			}
	
			@media only screen and (max-width: 600px) {
				font-size: 24px;
				margin-top: 36px;
			}
		}
		h3 {
			font-weight: 700;
		}
		
		h4 {
			width: 200px;
			font-weight: 400;
			font-size: 26px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.stages--text {
		text-align: center;
		margin-top: 20px;
		font-size: 20px;
		letter-spacing: 1.2px;
		display: flex;
		justify-content: center;
		align-items: center;

		@media only screen and (min-width: 601px) and (max-width: 1450px) {
			max-width: 250px;
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}

	.stages--icon {
		height: 60px;
		margin-bottom: 18px;

		filter: invert(99%) sepia(0%) saturate(519%) hue-rotate(160deg) brightness(111%) contrast(79%);

		@media only screen and (min-width: 601px) and (max-width: 1450px) {
			height: 100px;
			margin-top: 40px;
		}

		@media only screen and (max-width: 600px) {
			height: 80px;
		}
	}

	#mailIcon {
		scale: 0.8;
	}


}


// 7. OFFER
.offer-section {
	padding: 60px 10vw 100px 10vw;
	color: var(--main-text-color);
	background-color: var(--secondary-bg-color);

	.offer--container {
		margin-top: 60px;
		width: 100%;
		gap: 80px;
		display: grid;
		place-items: center;
		grid-template-columns: 1fr 1fr 1fr 1fr;

		@media only screen and (min-width: 601px) and (max-width: 1000px) {
			grid-template-columns: 1fr 1fr;
		}

		@media only screen and (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}

	.offer--single {
		max-width: 200px;
		height: 200px;

		p {
			text-align: center;
			margin-top: 20px;
			font-size: 20px;
			letter-spacing: 1.3px;
		}
	}

	.offer--icon {
		height: 100px;
		margin-bottom: 18px;

		filter: invert(99%) sepia(0%) saturate(519%) hue-rotate(160deg) brightness(111%) contrast(79%);
	}
}

// 8. CONTACT
.contact {
	position: relative;
	display: flex;
	justify-content: space-around;
	padding: 100px 30px 60px 30px;
	background: transparent;

	&::before {
		position: absolute;
		background-image: url("./assets/bgrass2.jpg");
		background-position: center;
		background-size: 2000px;
		-o-background-size: 2000px;
		-moz-background-size: 2000px;
		-webkit-background-size: 2000px;
		background-repeat: no-repeat;
		filter: blur(2px);
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		content: " ";
		z-index: -1;
	}

	backdrop-filter: blur(5px);

	@media only screen and (max-width: 400px) {
		padding: 26px;
	}

	@media only screen and (max-width: 1000px) {
		flex-direction: column;
		gap: 60px;
	}

	.contact--left {
		@media only screen and (max-width: 600px) {
			width: max(280px, 100%);
			padding: 20px;
		}

		@media only screen and (min-width: 601px) and (max-width: 1000px) {
			width: 100%;
			padding: 30px 60px;
		}

		@media only screen and (min-width: 1001px) and (max-width: 1200px) {
			padding: 20px;
		}

		height: fit-content;
		width: max(320px, 30vw);
		display: flex;
		flex-direction: column;
		background-color: var(--main-bg-color);
		gap: 12px;
		padding: 40px;

		.contact--title {
			font-size: 1.6rem;
			margin-bottom: 6px;
		}

		.contact--noicon {
			letter-spacing: 1.5px;

			&:last-of-type {
				margin-bottom: 12px;
			}
		}

		.contact--info {
			font-size: 1rem;
			overflow-wrap: break-word;
			display: flex;
			align-items: center;
		}

		img {
			align-self: center;
		}

		.logo {
			margin: 14px 0;
			width: 230px;
		}

		.google-maps {
			margin-left: 10px;
		}

		.social-links {
			justify-content: center;
			align-items: center;
			gap: 20px;
		}

		.icon-social {
			width: 30px;
		}
	}

	.contact--right {

		@media only screen and (max-width: 600px) {
			width: 100%;
			padding: 20px;
		}

		@media only screen and (min-width: 601px) and (max-width: 1000px) {
			width: 100%;
			padding: 30px 60px;
		}

		@media only screen and (min-width: 1001px) and (max-width: 1200px) {
			padding: 20px;
		}

		width: max(300px, 60vw);
		display: flex;
		flex-direction: column;
		gap: 10px;
		background-color: var(--main-bg-color);
		padding: 40px;

		.contact--title {
			font-size: 1.6rem;
			margin-bottom: 22px;
		}

		form {
			display: flex;
			flex-direction: column;
			align-items: center;

			.inputs-container {
				display: flex;
				gap: 20px;
				margin-bottom: 20px;
			}

			.cell {
				position: relative;
				flex: 1;
				margin: 10px 0;
				display: flex;
				justify-content: center;
			}

			label {
				z-index: 1;
				position: absolute;
				transform-origin: 0%;
				transition: all 400ms;
				pointer-events: none;
				top: 8px;
				left: 14px;
				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
				font-weight: 600;
				letter-spacing: 0.12rem;
				font-size: 0.8rem;
				color: #dddddd;
			}

			input {
				background-color: var(--main-bg-color);
				color: var(--main-text-color);
				width: 100%;
				height: 40px;
				border: solid #707070 1px;
				padding: 6px;
				font-size: 1rem;
				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';

				&:focus+label,
				&:not(:placeholder-shown)+label {
					transform: scale(0.8) translateY(-2.2rem);
				}

				&:focus {
					outline: none;
				}

				&:focus+label,
				&:not(:placeholder-shown)+label {
					transform: scale(0.8) translateY(-2.2rem);
				}

				&:focus {
					border: solid rgb(255, 255, 255) 1px;
				}
			}

			textarea {
				background-color: var(--main-bg-color);
				color: var(--main-text-color);
				width: 100%;
				height: 160px;
				border: solid #707070 1px;
				resize: vertical;
				padding: 6px;
				font-size: 1rem;
				font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI';
				margin-bottom: 18px;

				&:focus+label,
				&:not(:placeholder-shown)+label {
					transform: scale(0.8) translateY(-2.2rem);
				}

				&:focus+label,
				&:not(:placeholder-shown)+label {
					transform: scale(0.8) translateY(-2.2rem);
				}

				&:focus {
					border: solid white 1px;
					outline: none;
				}
			}

			.form--submit-btn {
				width: 200px;
				height: 40px;
				outline: none;
				border: 0;
				background-color: var(--main-text-color);
				color: var(--main-bg-color);
				border-radius: 4px;
				font-size: 16px;
				margin-top: 10px;
				transition: all 100ms;
				font-family: Roboto, -apple-system, BlinkMacSystemFont;

				&:focus,
				&:hover {
					transform: scale(1.1);
					cursor: pointer;
				}
			}
		}
	}
}